// Importe uniquement ce dont tu as besoin de Firebase
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions'; // Ajout pour les Functions


const firebaseConfig = {
    apiKey: "AIzaSyBUxATJ3oGot3bFJfCM2fJV09nITnMl5ao",
    authDomain: "aminerebeu-6d421.firebaseapp.com",
    projectId: "aminerebeu-6d421",
    storageBucket: "aminerebeu-6d421.appspot.com",
    messagingSenderId: "787339121122",
    appId: "1:787339121122:web:b63fb787a981cfede029bb",
    measurementId: "G-5GW52DGGBR"
};


// Initialise Firebase
const app = initializeApp(firebaseConfig);

// Obtient l'instance de l'authentification
const auth = getAuth(app);

// Obtient l'instance de Firestore
const db = getFirestore(app);
const functions = getFunctions(app);


// Obtient l'instance de Firebase Storage
const storage = getStorage(app);
const ADMIN_UID = "oYlB11SQsCfWuoCSjWVcwU0p9Az2";
export { auth, db, storage, app, ADMIN_UID, functions };
