import React from 'react';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate pour gérer la navigation
import './myaccount.css';


function Confidentiality() {
  const navigate = useNavigate(); // Crée une instance de navigate

  return (
    <div className="confidentiality-container">
<h2>   Politique de Confidentialité</h2>

<h3>Introduction</h3>
<p>Nous sommes pleinement engagés à protéger votre vie privée et à garantir la sécurité de vos données personnelles. Notre politique de confidentialité détaille nos pratiques concernant la collecte, l'utilisation, et la protection de vos informations.</p>
<h3>Collecte des Données
</h3>
<p>Nous prônons une approche minimale de la collecte de données. Ainsi, nous ne recueillons que votre adresse e-mail, strictement nécessaire pour :</p>

<ul>
<li>La création et la gestion de votre compte.
</li>
<li>L'envoi d'e-mails de vérification, conçus pour rester discrets.
</li>
<li>Nous n'utilisons pas de cookies et ne collectons aucune autre forme de données personnelles.
</li>
</ul>

<h3>Paiements et Sécurité
</h3>
<p>Les transactions financières sont confiées à Stripe Inc., un leader mondial des paiements en ligne, garantissant une sécurité optimale de vos données bancaires. Nous ne stockons aucune information financière sur nos serveurs.
</p>
<p>Pour renforcer la confidentialité, l'intitulé sur les relevés bancaires est dynamique, changeant chaque mois, ce qui rend impossible la corrélation directe entre vos achats et notre site.
</p>
<h3>Conservation des Données
</h3>
<p>Les seules autres données que nous conservons sont les historiques de vidéos liées aux adresses e-mail pour les achats effectués. Cette mesure assure un accès continu à vos contenus premium.
</p>
<h3>Engagement envers la Confidentialité
</h3>
<p>Nous prenons la confidentialité très au sérieux et adoptons toutes les mesures nécessaires pour protéger vos informations personnelles contre l'accès non autorisé, la divulgation, la modification ou la destruction.
</p>
<p>Nous nous engageons à maintenir la transparence et à vous tenir informé de toute modification de notre politique de confidentialité. Pour toute question, n'hésitez pas à nous contacter.
</p>
<p>Merci de nous faire confiance pour votre expérience média premium.
</p>      
<button onClick={() => navigate('/my-account')}>Retour à Mon Compte</button>
    </div>
  );
}

export default Confidentiality;
