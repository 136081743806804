import React, { useState } from 'react';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import './myaccount.css';

function UpdatePassword() {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleUpdatePassword = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess(false);

    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, currentPassword);

    // Réauthentifier l'utilisateur
    try {
      await reauthenticateWithCredential(user, credential);
      // Mettre à jour le mot de passe
      await updatePassword(user, newPassword);
      setSuccess(true);
    } catch (error) {
      setError("Erreur lors de la mise à jour du mot de passe. Assurez-vous que votre mot de passe actuel est correct.");
    }
  };

  return (
    <div className='update_password_container'>
      <h2>Mettre à jour votre mot de passe</h2>
      {success && <p>Mot de passe mis à jour avec succès.</p>}
      {error && <p>{error}</p>}
      <form onSubmit={handleUpdatePassword}>
        <label>
          Mot de passe actuel :
          <input type="password" value={currentPassword} onChange={(e) => setCurrentPassword(e.target.value)} required />
        </label>
        <label>
          Nouveau mot de passe :
          <input type="password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} required />
        </label>
        <label>
          Confirmez le nouveau mot de passe :
          <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
        </label>
        <button type="submit">Mettre à jour</button>
      </form>
    </div>
  );
}

export default UpdatePassword;
