import React from 'react';
import './MyAccount.css'; // N'oublie pas de créer et d'importer le CSS
import { useNavigate } from 'react-router-dom'; // Importe useNavigate
import { getAuth, signOut } from "firebase/auth"; // Importe getAuth et signOut de Firebase Auth
import { Box, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { red } from '@mui/material/colors';

function MyAccount() {
    const navigate = useNavigate(); // Crée une instance de navigate
    const auth = getAuth(); // Obtient l'instance de l'authentification Firebase

    const handleLogout = () => {
        signOut(auth).then(() => {
            // Déconnexion réussie
            console.log("Déconnexion réussie");
            navigate('/login'); // Redirige l'utilisateur vers la page de connexion après déconnexion
        }).catch((error) => {
            // Une erreur s'est produite
            console.error("Erreur de déconnexion", error);
        });
    };

  return (
    <Box
    sx={{
      backgroundColor: 'var(--secondary-black)',
      borderRadius: '10px',
      padding: '20px',
      // Appliquez d'autres styles ici pour un UI moderne
    }}
  >
    <List>
      <ListItem disablePadding>
        <ListItemButton onClick={() => navigate('/updatepassword')}>
          <ListItemText primary="Mettre à jour mon mot de passe" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => navigate('/confidentiality')}>
          <ListItemText primary="Politique de confidentialité" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={() => navigate('/deleteaccount')} sx={{ color: red[500] }}>
          <ListItemText primary="Supprimer mon compte" />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton onClick={handleLogout} sx={{ color: red[500] }}>
          <ListItemText primary="Se Deconnecter" />
        </ListItemButton>
      </ListItem>
    </List>
  </Box>
  );
}

export default MyAccount;
