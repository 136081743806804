import React, { useState, useEffect } from 'react';
import { storage, db, ADMIN_UID, auth } from '../../firebaseConfig'; // Vérifiez ce chemin
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { onAuthStateChanged } from 'firebase/auth';
import { useNavigate } from 'react-router-dom'; // Importe useNavigate

import './AdminUploadVid.css'; // Assurez-vous d'avoir ce fichier CSS



function AdminUploadVid() {
  const navigate = useNavigate();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const auth = getAuth();




  const [videos, setVideos] = useState([]); // Maintenant un tableau
  const [previews, setPreviews] = useState([]);
  const [price, setPrice] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [totalDuration, setTotalDuration] = useState(0);
  const [tags, setTags] = useState('');
  const [modalVisible, setModalVisible] = useState(false);
  const [videoLink, setVideoLink] = useState('');
  const [copyText, setCopyText] = useState('');


// Dans handleSubmit, après avoir défini videoLink


  const calculateVideoDuration = (file) => {
    return new Promise((resolve) => {
      const videoElement = document.createElement('video');
      videoElement.src = URL.createObjectURL(file);

      videoElement.addEventListener('loadedmetadata', () => {
        console.log(`Durée de la vidéo : ${videoElement.duration} secondes`);
        resolve(videoElement.duration);
        URL.revokeObjectURL(videoElement.src);
      });
    });
  };

  const handleVideosChange = (e) => {
    setVideos([...e.target.files].slice(0, 5)); // Limite à 5 vidéos, similaire aux previews
  };

  const handlePreviewsChange = (e) => {
    setPreviews([...e.target.files].slice(0, 5)); // Limite à 5 previews
  };
  const copyToClipboard = () => {
    navigator.clipboard.writeText(copyText).then(() => {
      alert("Lien copié dans le presse-papiers !");
    }).catch(err => {
      console.error('Erreur lors de la copie :', err);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (videos.length === 0 || previews.length === 0) return;
    const tagsArray = tags.split(',').map(tag => tag.trim()).filter(tag => tag !== '');
    // Vérifie si les vidéos et les previews sont sélectionnés
    let totalDurationTemp = 0;
    for (const video of videos) {
        const duration = await calculateVideoDuration(video);
        totalDurationTemp += duration;
    }

    setTotalDuration(totalDurationTemp);
    try {
      const uploadTasks = videos.map((video) => {
        const videoRef = ref(storage, `videos/${video.name}`);
        const uploadTask = uploadBytesResumable(videoRef, video);
        return uploadTask;
      });
  
      // Utiliser Promise.all pour gérer plusieurs uploads simultanément
      const uploadResults = await Promise.all(uploadTasks.map((uploadTask) => 
        new Promise((resolve, reject) => {
          uploadTask.on('state_changed', 
            (snapshot) => {
              // Calculer la progression en pourcentage
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setUploadProgress(progress);
              console.log('Upload is ' + progress + '% done');
            }, 
            (error) => reject(error), 
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                resolve(downloadURL);
              });
            }
          );
        })
      ));
  
      console.log('Tous les fichiers ont été uploadés : ', uploadResults);
      // Suite de votre code pour traiter les résultats d'upload...
  
    } catch (error) {
        console.error("Erreur lors de l'upload : ", error);
        alert("Erreur lors de l'upload.");
    }

    try {
   
        const storagePaths = await Promise.all(videos.map(async (video) => {
          const videoRef = ref(storage, `videos/${video.name}`);
          const videoUploadTask = uploadBytesResumable(videoRef, video);
      
          
          // Attendre que l'upload soit complet avant de résoudre la promesse avec le chemin de stockage.
          await new Promise((resolve, reject) => {
              videoUploadTask.on('state_changed', null, reject, () => resolve());
          });
      
          // Retourner le chemin complet après que l'upload est terminé.
          return videoRef.fullPath;
      }));

        // Téléchargement des previews (inchangé)
        const previewUrls = await Promise.all(previews.map(async (preview) => {
            const previewRef = ref(storage, `previews/${preview.name}`);
            const previewUploadTask = uploadBytesResumable(previewRef, preview);
            await previewUploadTask;
            return getDownloadURL(previewRef);
        }));

        const numericPrice = parseFloat(price);

        console.log(`Durée totale calculée : ${totalDurationTemp} secondes`);

        // Création de l'entrée dans Firestore avec les chemins de stockage
        if (storagePaths.every(path => path !== undefined) && previewUrls.every(url => url !== undefined)) {
          const docRef = await addDoc(collection(db, "videos"), {
            title,
            description,
            price: numericPrice,
            storagePaths, // Stocke un tableau de chemins de stockage
            previewUrls,
            duration: Math.round(totalDurationTemp / 60),
            tags: tagsArray, // Ajout des tags ici
        });

        // docRef.id contient l'ID du document nouvellement créé
        console.log("ID du document Firestore créé :", docRef.id);

        // Utilisez docRef.id pour afficher ou utiliser l'ID du document comme nécessaire
        alert(`Vidéos et previews téléchargés avec succès ! ID du document : ${docRef.id}`);
        
        // Mettez à jour l'état ou la logique de l'UI ici si nécessaire, par exemple pour afficher le lien
        // Voici un exemple de formation d'un lien avec l'ID du document
        const videoLink = `aminerebeu.com/video/${docRef.id}`;
        setVideoLink(videoLink);
        
        const fullText = `Nouvelle vidéo dispo sur mon site : ${title}\nRegardez-la ici : ${videoLink}\n#kiffeur #kiffeurtn #cashmaster #findom #cashslave #feetmaster #footmaster #gayfeet #feetslave #maitredz #feetmaster #footmaster #slavebottom #cashmasterdz`;
setCopyText(fullText);

        
        // Assurez-vous d'avoir un état appelé videoLink
        setModalVisible(true); // Active l'affichage de la modale avec le lien
        
        } else {
            console.error("Une ou plusieurs chemins sont undefined.");
        }
    } catch (error) {
        console.error("Erreur lors du téléchargement : ", error);
        alert("Erreur lors du téléchargement.");
    }



};



  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && user.uid === ADMIN_UID) {
        // L'utilisateur est l'admin
        setIsAuthorized(true);
      } else {
        // L'utilisateur n'est pas l'admin ou non connecté
        navigate('/login'); // Redirige vers la page de connexion ou toute autre page
      }
    });
  }, [auth, navigate]);

  if (!isAuthorized) {
    return null; // Affiche un loader ou retourne null pendant la vérification
  }
  return (
    <div className="admin-upload-container">
      {modalVisible && (
  <div className="modaltxt">
    <p>Prêt à partager :</p>
    <textarea readOnly value={copyText} style={{width: '100%', height: '150px'}} />
    <button onClick={copyToClipboard}>Copier le texte complet</button>
    <button onClick={() => setModalVisible(false)}>Fermer</button>
  </div>
)}

      <h2>Publier</h2>
      
      <form onSubmit={handleSubmit}>
        <h4>Vidéo(s) : </h4>
        <input type="file" accept="video/*" multiple onChange={handleVideosChange} required />
        <h4>Image(s) de preview (max. 5) :</h4>

        <input type="file" accept="image/*" multiple onChange={handlePreviewsChange} required />
        <h4>Titre :</h4>

        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} placeholder="Titre" required />
        <h4>Description :</h4>

        <textarea value={description} onChange={(e) => setDescription(e.target.value)} placeholder="Description" required></textarea>
        <h4>Prix :</h4>

        <input
  type="number"
  value={price}
  onChange={(e) => setPrice(parseFloat(e.target.value))}
  placeholder="Prix"
  required
/>
<h4>Tags (séparés par un point-virgule) :</h4>
<input
  type="text"
  value={tags}
  onChange={(e) => setTags(e.target.value)}
  placeholder="Exemple: tag1; tag2; tag3"
  required
/>

        <button type="submit">Télécharger</button>
        <div className="progress-bar-container">
          <div className="progress-bar" style={{ width: `${uploadProgress}%` }}></div>
        </div>
      </form>
    </div>
  );
}

export default AdminUploadVid;
