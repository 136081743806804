import React, { useState, useEffect } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
// Importe l'authentification depuis ta configuration Firebase
import LoginPage from './components/LoginPage';
import Feed from './components/Feed';
import Header from './components/Header';
import MyAccount from './components/MyAccount';
import VideoView from './components/VideoView';
import PurchasedVideoView from './components/PurschasedVideoView';
import AdminUploadVid from './components/Admin/AdminUploadVid';
import DeleteAccount from './components/Myaccount/DeleteAccount';
import Confidentiality from './components/Myaccount/Confidentiality';
import LastPurchased from './components/Admin/LastPurchased';
import HeaderAdmin from './components/Admin/HeaderAdmin';
import UpdatePassword from './components/Myaccount/Updatepassword';
import VideoManagement from './components/Admin/VideoManagement';
import AdminRoute from './AdminRoute'; // Assure-toi que le chemin est correct
import AdminUploadPack from './components/Admin/AdminUploadPack';

import { auth, ADMIN_UID } from './firebaseConfig'; // Importe ta config Firebase
import { onAuthStateChanged } from 'firebase/auth';
const RouteChangeTracker = () => {
  const location = useLocation();

  React.useEffect(() => {
    NProgress.start();
    return () => {
      setTimeout(() => NProgress.done(), 1000); // Assurez-vous que NProgress s'arrête après un délai
    };
}, [location]);
};

function App() {
  const [isAuthorized, setIsAuthorized] = useState(false);

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user && user.uid === ADMIN_UID) {
        setIsAuthorized(true);
      } else {
        setIsAuthorized(false);
      }
    });
  }, []);
  return (
    
     <Router>
            <RouteChangeTracker /> {/* Surveille les changements de route */}

      {isAuthorized ? <HeaderAdmin /> : <Header />}
   
            <div className='App'>

       
      <Routes>
        <Route path="/" element={<Feed />} />
        <Route path="/video/:id" element={<VideoView />} />
        <Route path="/purchased-videos/video/:id" element={<PurchasedVideoView />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/my-account" element={<MyAccount />} />
        <Route path="/updatepassword" element={<UpdatePassword />} />
        <Route path="/deleteaccount" element={<DeleteAccount />} />
        <Route path="/confidentiality" element={<Confidentiality />} />
        <Route
          path="/adminUpload"
          element={
            <AdminRoute>
              <AdminUploadVid />
            </AdminRoute>
          }
        />
        <Route
          path="/lastpurchased"
          element={
            <AdminRoute>
              <LastPurchased />
            </AdminRoute>
          }/>
        <Route
          path="/videomanagement"
          element={
            <AdminRoute>
              <VideoManagement />
            </AdminRoute>
          }
        />
        <Route
          path="/adminUploadPack"
          element={
            <AdminRoute>
              <AdminUploadPack />
            </AdminRoute>
          }
        />
        
      
        
              </Routes>
      </div>
    </Router>
    
  );
}

export default App;
