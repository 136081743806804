import React, { useState } from 'react';
import '../Header.css'; // Assure-toi d'avoir importé ton CSS
import menuIcon from '../Icons/menu.svg'; // Ton icône de menu
import { useNavigate } from 'react-router-dom'; // Importe useNavigate si nécessaire

function HeaderAdmin() {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };


  return (
    <div className="bottombarcontainer">
      <div className="bottom-bar">
        <span className="creator-name">@AmineRebeu38</span>

        <button className="icon-button" onClick={handleMenuToggle}>
          <img src={menuIcon} alt="Menu" />
        </button>
       
      </div>
      {showMenu && (
          <div className="menu-dropdown">
            <ul>
            <li onClick={() => navigate('/lastpurchased')}>Derniers achats</li>
            <li onClick={() => navigate('/adminUpload')}>Publier une vidéo</li>
            <li onClick={() => navigate('/adminUploadPack')}>Créer un pack</li>

            <li onClick={() => navigate('/videomanagement')}>Gérer mes vidéos</li>
            <li onClick={() => navigate('/')}>Voir mon feed</li>



              {/* Ajoute d'autres options spécifiques à l'administrateur ici */}
            </ul>
          </div>
        )}
    </div>
  );
}

export default HeaderAdmin;
