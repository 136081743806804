import React from 'react';
import './VideoView.css'; // Assure-toi d'avoir ce fichier CSS pour styliser ta page
import { useParams, useNavigate } from 'react-router-dom'; // Ajoute useNavigate ici
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

const auth = getAuth();

onAuthStateChanged(auth, (user) => {
    if (user) {
      // L'utilisateur est connecté, affiche l'UID
      console.log(`L'UID de l'utilisateur connecté est : ${user.uid}`);
    } else {
      // L'utilisateur est déconnecté
      console.log("Aucun utilisateur connecté.");
    }
  });

function VideoView() {

    
  // Données d'exemple pour une vidéo
  const [video, setVideo] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Initialise useNavigate


  const { id } = useParams();

  useEffect(() => {
    
    const fetchVideo = async () => {
      const docRef = doc(db, "videos", id);
      const docSnap = await getDoc(docRef);
  
      if (docSnap.exists()) {
        setVideo({ id: docSnap.id, ...docSnap.data() });
      } else {
        console.log("Aucune vidéo trouvée !");
      }
    };
  
    fetchVideo();
  }, [id]);

  const handlePurchase = async () => {
    if (auth.currentUser) {
      NProgress.start(); // Démarrer NProgress lorsqu'un achat est initié
      setLoading(true);
      const functions = getFunctions();
      const createCheckoutSession = httpsCallable(functions, 'createStripeCheckoutSession');
  
      try {
        const { data } = await createCheckoutSession({
          stripePriceId: video.stripePriceId,
          videoId: video.id,
          userUid: auth.currentUser.uid
        });
        window.location.href = data.url; // Redirige l'utilisateur vers Stripe Checkout
      } catch (error) {
        console.error("Erreur lors de la création de la session de paiement :", error);
        // Gérez ici l'erreur, par exemple, affichez un message à l'utilisateur
      } finally {
        NProgress.done(); // Arrête NProgress une fois que l'opération est terminée ou a échoué
        setLoading(false);
      }
    } else {
      navigate('/login'); // Redirige vers la page de connexion si l'utilisateur n'est pas connecté
    }
  };

  function openTab(evt, tabName) {
    var i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    tablinks = document.getElementsByClassName("tablinks");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    evt.currentTarget.className += " active";
  }
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');

  const openModal = (image) => {
    setSelectedImage(image);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedImage('');
  };

  const prevImage = (e) => {
    e.stopPropagation(); // Empêche l'événement de se propager au conteneur de la modale
    const currentIndex = video.previewUrls.findIndex(url => url === selectedImage);
    const prevIndex = (currentIndex - 1 + video.previewUrls.length) % video.previewUrls.length;
    setSelectedImage(video.previewUrls[prevIndex]);
  };
    
  const nextImage = (e) => {
    e.stopPropagation(); // Empêche l'événement de se propager au conteneur de la modale
    const currentIndex = video.previewUrls.findIndex(url => url === selectedImage);
    const nextIndex = (currentIndex + 1) % video.previewUrls.length;
    setSelectedImage(video.previewUrls[nextIndex]);
  };
  

 return (
    <div className="video-view-container">
         
    {video ? (
      <>
        <h1 className="video-title">{video.title}</h1>
        <div className="tab">
  <button className="tablinks margin1 active"  onClick={(e) => openTab(e, 'Aperçu')}>Aperçu</button>
  <button className="tablinks margin2" onClick={(e) => openTab(e, 'PlusInfos')}>+ d'infos</button>
</div>

        <div id="Aperçu" style={{display: "block"}} className="tabcontent">
          <div className="image-gallery">
            {video.previewUrls.map((image, index) => (
              <img key={index} src={image} alt={`Aperçu ${index + 1}`} onClick={() => openModal(image)}  />
            ))}
          
          </div>
        </div>
        {isModalOpen && (
  <div className="modal" onClick={closeModal}>
    <span className="close-modal" onClick={closeModal}>&times;</span>
    <span className="modal-arrow left-arrow" onClick={prevImage}>&lt;</span>
    <span className="modal-arrow right-arrow" onClick={nextImage}>&gt;</span>
    <img src={selectedImage} alt="Agrandi" onClick={(e) => e.stopPropagation()} />
  </div>
)}

        <div id="PlusInfos" className="tabcontent">
          <div className="plusdinfos">
            <p className='description'>Description : {video.description}</p>
          </div>
        </div>
        <div className="detail">
          <h4>Détail du paiement</h4>
          <div className="payment-info">
            <p>Média(s) :</p><p>{video.price} € TTC</p>
          </div>
          <div className="payment-info">
            <p>Durée totale :</p><p>{video.duration} minutes</p>
          </div>
          <div className="payment-info">
            <p>Téléchargement :</p><p>inclus</p>
          </div>
         
          <button className="purchase-button" onClick={handlePurchase}>Acheter</button>
          <p className="secure-payment">Paiement sécurisé et intitulé discret</p>
        </div>
      </>
    ) : (
      <p>Chargement...</p>
    )}
</div>

);

}

export default VideoView;
