import React, { useState, useEffect } from 'react';
import { auth } from '../firebaseConfig';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { Box, Button, Divider, TextField, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

function LoginPage() {
  const navigate = useNavigate();
  const [isSignUp, setIsSignUp] = useState(false); // Basculer entre connexion et inscription
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isResettingPassword, setIsResettingPassword] = useState(false); // Nouveau: état pour réinitialisation de mot de passe
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate('/my-account');
      }
    });
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage('');
    if (isSignUp) {
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Inscription réussie
        })
        .catch((error) => {
          setErrorMessage(getFriendlyErrorMessage(error.code));
        });
    } else if (!isResettingPassword) {
      signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Connexion réussie
        })
        .catch((error) => {
          setErrorMessage(getFriendlyErrorMessage(error.code));
        });
    } else {
      handleResetPassword();
    }
  };

  const handleResetPassword = () => {
    sendPasswordResetEmail(auth, email)
      .then(() => {
        setErrorMessage("Un email de réinitialisation de mot de passe a été envoyé.");
        setIsResettingPassword(false); // Réinitialise l'état après l'envoi
      })
      .catch((error) => {
        setErrorMessage(getFriendlyErrorMessage(error.code));
      });
  };
  const handleSignInWithGoogle = () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
      .then((result) => {
        // Cela donne un objet Google User
        const user = result.user;
        // Tu peux ensuite utiliser les informations de l'utilisateur comme tu le souhaites
        console.log(user);
      })
      .catch((error) => {
        // Gérer les erreurs ici
        const errorCode = error.code;
        const errorMessage = error.message;
        // L'email de l'utilisateur qui a été utilisé pour la tentative de connexion
        const email = error.email;
        // Le type de firebase.auth.AuthCredential qui a été utilisé
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.error("Erreur de connexion avec Google:", errorCode, errorMessage, email, credential);
      });
  };
  // Fonction pour obtenir des messages d'erreur conviviaux
  function getFriendlyErrorMessage(errorCode) {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'Cet email est déjà utilisé par un autre compte.';
      case 'auth/weak-password':
        return 'Le mot de passe doit contenir au moins 6 caractères.';
      case 'auth/user-not-found':
        return 'Aucun utilisateur trouvé avec cet email.';
      case 'auth/wrong-password':
        return 'Mot de passe incorrect.';
      case 'auth/too-many-requests':
        return 'Trop de tentatives. Essayez à nouveau plus tard.';
      default:
        return 'Une erreur est survenue. Veuillez réessayer.';
    }
  }

  return (
    // UI adaptée
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        backgroundColor: 'var(--secondary-black)',
        color: 'white',
        '.MuiTextField-root': { m: 1, width: '75%' },
        '.MuiButton-root': {
          m: 1,
          width: '75%',
          borderRadius: '20px',
          backgroundColor: 'var(--accent-color)',
          '&:hover': {
            backgroundColor: 'var(--accent-color-dark)',
          },
        },
      }}
    >
      <Typography variant="google" component="h2" marginBottom={2}>
        {isResettingPassword ? "Réinitialiser le mot de passe" : (isSignUp ? "S'inscrire" : "Se connecter")}
      </Typography>

      { !isResettingPassword && 
        <Button
          variant="contained" onClick={handleSignInWithGoogle}
          startIcon={<GoogleIcon />}
          sx={{ borderRadius: '20px', width: '75%' }}
        >
          Continuer avec Google
        </Button>
      }

      { !isResettingPassword && 
        <Divider sx={{ my: 1, width: '75%' }}>
          <Typography variant="caption" component="div" sx={{ color: 'white', background: 'var(--secondary-black)', px: 2 }}>
            ou
          </Typography>
        </Divider>
      }

      <TextField
        label="Email"
        variant="outlined"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        fullWidth
        sx={{
          backgroundColor: 'white',
          borderRadius: '20px',
          '.MuiOutlinedInput-notchedOutline': {
            borderColor: 'var(--accent-color)',
          },
          width: '75%',
        }}
      />

      {!isResettingPassword && (
        <TextField
          label="Password"
          variant="outlined"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          fullWidth
          sx={{
            backgroundColor: 'white',
            borderRadius: '20px',
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: 'var(--accent-color)',
            },
            width: '75%',
          }}
        />
      )}

      {!isResettingPassword ? (
        <>
          {isSignUp ? (
            <Button type="submit" variant="contained" onClick={handleSubmit}>S'inscrire</Button>
          ) : (
            <Button type="submit" variant="contained" onClick={handleSubmit}>Se connecter</Button>
          )}
          {!isSignUp && <Typography sx={{ mt: 2, cursor: 'pointer', width: '75%', textAlign: 'center' }} onClick={() => setIsResettingPassword(true)}>Mot de passe oublié ?</Typography>}
        </>
      ) : (
        <Button type="submit" variant="contained" onClick={handleSubmit}>Réinitialiser</Button>
      )}

      {!isSignUp && !isResettingPassword && <Typography sx={{ mt: 2, cursor: 'pointer', width: '75%', textAlign: 'center', color: 'var(--accent-color)' }} onClick={() => setIsSignUp(true)}>Pas encore client ? S'inscrire</Typography>}
      {isSignUp && !isResettingPassword && <Typography sx={{ mt: 2, cursor: 'pointer', width: '75%', textAlign: 'center' }} onClick={() => setIsSignUp(false)}>Déjà inscrit ? Se connecter</Typography>}
      {isResettingPassword && <Typography sx={{ mt: 2, cursor: 'pointer', width: '75%', textAlign: 'center' }} onClick={() => setIsResettingPassword(false)}>Se connecter</Typography>}

      

      {errorMessage && <Typography color="error" sx={{ mt: 2, width: '75%', textAlign: 'center' }}>{errorMessage}</Typography>}
    </Box>
  );
}

export default LoginPage;
