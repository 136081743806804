import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import './Header.css';
import layoutIcon from './Icons/layout.svg';
import userIcon from './Icons/user.svg';
import vipIcon from './Icons/vip.svg';

function Header() {
  const navigate = useNavigate();
  const auth = getAuth();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Observer l'état de connexion
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return unsubscribe; // Se désabonner de l'observateur lors du démontage du composant
  }, []);

  const handleFeedClick = () => {
    navigate('/'); // Navigue vers la page d'accueil
  };

  const handleUserClick = () => {
    if (user) {
      navigate('/my-account'); // Navigue vers MyAccount si l'utilisateur est connecté
    } else {
      navigate('/login'); // Navigue vers Login si aucun utilisateur n'est connecté
    }
  };

  return (
   <div className="bottombarcontainer">
     <div className="bottom-bar">
     <span className="creator-name">@AmineRebeu38</span>

      <button className="icon-button" onClick={handleFeedClick}>
        <img src={layoutIcon} alt="Feed" />
      </button>
      <button className="icon-button" onClick={handleUserClick}>
        <img src={userIcon} alt={"Compte"} />
      </button>
    </div>

   </div>
  );
}

export default Header;
