import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import './LastPurschased.css';
const LastPurchased = () => {
  const [lastPurchases, setLastPurchases] = useState(null);

  useEffect(() => {
    const fetchLastPurchases = async () => {
      const functions = getFunctions();
      const getLastPurchases = httpsCallable(functions, 'getLastPurchasesAndBalance');

      try {
        const { data } = await getLastPurchases();
        setLastPurchases(data);
      } catch (error) {
        console.error("Erreur lors de la récupération des derniers achats :", error);
      }
    };

    fetchLastPurchases();
  }, []);
  const translateStatus = (status) => {
    switch(status) {
      case 'succeeded':
        return 'Réussi';
      case 'canceled':
        return 'Annulé';
      // Ajoute d'autres cas au besoin
      default:
        return 'Inconnu';
    }
  };
  

  if (!lastPurchases) {
    return <div>Chargement...</div>;
  }
  
  return (
<div className='financial'>
  <h3 class="dashboard-title">Dashboard Financier</h3>
  <div class="balance-info">
    <p>Balance Disponible : <span>{lastPurchases.availableBalance}€</span></p>
    <p>Balance en Attente : <span>{lastPurchases.pendingBalance}€</span></p>
  </div>
  <div class="purchases">
    {lastPurchases.purchases.map((purchase, index) => (
      <div class="purchase-card" key={index}>
        <p className='amount'>Montant : <span>{purchase.amount}€</span></p>
        <p>Status: <span className={`status ${purchase.status === 'succeeded' ? 'status-succeeded' : 'status-canceled'}`}>{translateStatus(purchase.status)}</span></p>
        <p>Date : <span>{purchase.date}</span></p>
      </div>
    ))}
  </div>
</div>


  );
};

export default LastPurchased;