import React, { useState } from 'react';
import { getAuth, reauthenticateWithCredential, EmailAuthProvider, deleteUser } from "firebase/auth";
import './myaccount.css';


function DeleteAccount() {
  const [password, setPassword] = useState('');
  const [confirmationText, setConfirmationText] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleDeleteAccount = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess(false);

    if (confirmationText !== "Confirmer la suppression du compte") {
      setError("Vous devez recopier exactement la phrase demandée.");
      return;
    }

    const auth = getAuth();
    const user = auth.currentUser;
    const credential = EmailAuthProvider.credential(user.email, password);

    // Réauthentifier l'utilisateur
    try {
      await reauthenticateWithCredential(user, credential);
      // Supprimer le compte
      await deleteUser(user);
      setSuccess(true);
      alert("Compte supprimé avec succès. Vous allez être redirigé.");
      // Redirection ou autres actions après suppression
    } catch (error) {
      setError("Erreur lors de la suppression du compte. Vérifiez votre mot de passe.");
    }
  };

  return (
    <div className="delete-account-container">
      <h2>Supprimer votre compte</h2>
      <p>Attention, toute suppression de compte est définitive. Toute vidéo achetée sera perdue si vous ne l'avez pas téléchargée.</p>
      <form onSubmit={handleDeleteAccount}>
        {success && <p>Compte supprimé avec succès.</p>}
        {error && <p className='errortext'>{error}</p>}
        <label>
          Pour confirmer, recopiez la phrase suivante : "Confirmer la suppression du compte"
          <input type="text" value={confirmationText} onChange={(e) => setConfirmationText(e.target.value)} required />
        </label>
        <label>
          Votre mot de passe actuel :
          <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
        </label>
        <button type="submit">Supprimer mon compte</button>
      </form>
    </div>
  );
}

export default DeleteAccount;
