import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { auth, app } from '../firebaseConfig'; // Chemin ajusté pour l'importation de la configuration

const PurchasedVideoView = () => {
  const [videoUrls, setVideoUrls] = useState([]);
  const { id: videoId } = useParams(); // Extraction de l'ID de la vidéo directement
  
  useEffect(() => {
    // Vérification de l'authentification de l'utilisateur
    if (!auth.currentUser) {
      console.log("Veuillez vous connecter pour accéder à cette vidéo.");
      return;
    }
    
    // Initialisation de Firebase Functions
    const functions = getFunctions(app);
    const getAccessVideo = httpsCallable(functions, 'getAccessVideo');
    
    // Appel à la fonction Cloud avec l'ID de la vidéo
    getAccessVideo({ videoId })
      .then(result => {
        // Succès : Met à jour l'état avec les URLs signées obtenues
        setVideoUrls(result.data.urls); // Assurez-vous que la fonction renvoie un objet avec une propriété `urls`
      })
      .catch(error => {
        // Gestion des erreurs de l'appel de fonction
        console.error("Erreur d'accès à la vidéo :", error);
      });
  }, [videoId]); // Dépendance à `videoId` pour recharger si celui-ci change

  return (
    <div className="purchased-video-view-container">
      {videoUrls.length > 0 ? (
        videoUrls.map((url, index) => (
          <div key={index} className="video-container">
            <video controls src={url} width="100%"></video>
          </div>
        ))
      ) : (
        <p>Chargement en cours... Veuillez rafraîchir la page ou vous reconnecter si cela prend trop de temps.</p>
      )}
    </div>
  );
};

export default PurchasedVideoView;
