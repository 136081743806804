import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig';
import { collection, getDocs, addDoc } from 'firebase/firestore';
import './AdminUploadPack.css';
function AdminUploadPack() {
  const [existingVideos, setExistingVideos] = useState([]);
  const [selectedVideoIds, setSelectedVideoIds] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [tags, setTags] = useState('');

  useEffect(() => {
    // Récupère la liste des vidéos existantes pour permettre à l'admin de les sélectionner pour le pack
    const fetchVideos = async () => {
      const querySnapshot = await getDocs(collection(db, "videos"));
      setExistingVideos(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchVideos();
  }, []);

  const handleSelectVideo = (videoId) => {
    // Gère la sélection/désélection des vidéos pour le pack
    setSelectedVideoIds(prevSelectedVideoIds =>
      prevSelectedVideoIds.includes(videoId)
        ? prevSelectedVideoIds.filter(id => id !== videoId)
        : [...prevSelectedVideoIds, videoId]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Filtre les vidéos sélectionnées basées sur les IDs
    const selectedVideos = existingVideos.filter(video => selectedVideoIds.includes(video.id));
    const tagsArray = tags.split(',').map(tag => tag.trim()).filter(tag => tag !== '');

    // Récupère la première preview de chaque vidéo sélectionnée
    const previewUrls = selectedVideos.map(video => video.previewUrls[0]);
    const storagePaths = selectedVideos.flatMap(video => video.storagePaths);
    const duration = selectedVideos.reduce((acc, video) => acc + video.duration, 0);


    // Prépare les données pour le pack
    const packData = {
      title,
      description,
      price: parseFloat(price), // Assurez-vous que le prix est en format numérique
      previewUrls,
      storagePaths,
      duration,
      tagsArray // Utilisez ce tableau pour le document du pack
    };

    console.log('Pack data:', packData);

    // Ajoutez ici la logique pour créer le document du pack dans Firestore
    try {
      const docRef = await addDoc(collection(db, "videos"), packData);
      console.log("Pack créé avec ID: ", docRef.id);
      // Ajoutez ici la logique pour créer le produit/prix Stripe si nécessaire
    } catch (error) {
      console.error("Erreur lors de la création du pack: ", error);
    }
  };


  return (
    <div className="admin-upload-pack">
      <form onSubmit={handleSubmit}>
        <h2>Créer un pack de vidéos</h2>
        <div className="video-selection">
          {existingVideos.map(video => (
            <div key={video.id} className="video-item">
              <input
                type="checkbox"
                id={`video-${video.id}`}
                checked={selectedVideoIds.includes(video.id)}
                onChange={() => handleSelectVideo(video.id)}
              />
              <label htmlFor={`video-${video.id}`}>{video.title}</label>
            </div>
          ))}
        </div>
        <div className="pack-details">
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Titre du pack"
            required
          />
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Description du pack"
            required
          />
          <input
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            placeholder="Prix du pack"
            required
          />
          <h4>Tags (séparés par un point-virgule) :</h4>
<input
  type="text"
  value={tags}
  onChange={(e) => setTags(e.target.value)}
  placeholder="Exemple: tag1; tag2; tag3"
  required
/>
        </div>
        <button type="submit">Créer le pack</button>
      </form>
    </div>
  );
}

export default AdminUploadPack;
