import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ADMIN_UID } from './firebaseConfig'; // Assure-toi que le chemin est correct

const AdminRoute = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
      if (user && user.uid === ADMIN_UID) {
        setIsAdmin(true);
      } else {
        navigate('/login'); // Redirige vers la page de connexion
      }
      setIsLoading(false);
    });
  }, [navigate]);

  if (isLoading) {
    return <div>Chargement...</div>; // Ou tout autre indicateur de chargement
  }

  return isAdmin ? children : null;
};

export default AdminRoute;
