import React, { useState, useEffect } from 'react';
import { db } from '../../firebaseConfig'; // Importe ton instance de la base de données Firebase
import { doc, updateDoc, deleteDoc, collection, getDocs } from 'firebase/firestore';
import { getFunctions, httpsCallable } from "firebase/functions"; // Pour appeler des fonctions Cloud Firestore
import { Box, TextField, Button, Typography, Card, CardContent } from '@mui/material';

function VideoManagement() {
  const [videos, setVideos] = useState([]);
  const [editState, setEditState] = useState({});
  const [isEditing, setIsEditing] = useState({});
  useEffect(() => {
    const fetchVideos = async () => {
      const videosCollectionRef = collection(db, "videos");
      const querySnapshot = await getDocs(videosCollectionRef);
      setVideos(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      
      let initialEditState = {};
      let initialIsEditing = {};
      
      querySnapshot.docs.forEach(doc => {
        // Ici, `doc` est correctement défini comme un snapshot de document,
        // donc vous pouvez sans problème accéder à `doc.data()` et à ses propriétés.
        const docData = doc.data(); // Accède aux données du document actuel
        const tagsString = docData.tags ? docData.tags.join('; ') : ''; // Gère le cas où les tags sont undefined
  
        initialEditState[doc.id] = {
          title: docData.title, 
          price: docData.price, 
          description: docData.description,
          tags: tagsString, // Correctement placé à l'intérieur de la boucle
        };
  
        initialIsEditing[doc.id] = false;
      });
  
      setEditState(initialEditState);
      setIsEditing(initialIsEditing);
    };
    fetchVideos();
  }, []);
  
  // Ajoute cette fonction pour appeler ta fonction Cloud Firestore
  const updateStripeProduct = async (videoId, updatedData) => {
    const updateStripeProductFunction = httpsCallable(getFunctions(), 'updateStripeProductAndPrice');
    try {
      await updateStripeProductFunction({ videoId, ...updatedData });
      console.log('Produit Stripe mis à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour du produit Stripe:', error);
    }
  };

  const handleUpdate = async (videoId) => {
    // Séparez les tags du reste des données mises à jour
    const { tags, ...otherUpdatedData } = editState[videoId];
  
    // Convertissez la chaîne de caractères des tags en un tableau,
    // en supprimant les espaces inutiles et en filtrant les chaînes vides
    const tagsArray = tags.split(';').map(tag => tag.trim()).filter(tag => tag !== '');
  
    // Combine les données mises à jour avec les tags transformés en tableau
    const updatedData = { ...otherUpdatedData, tags: tagsArray };
  
    try {
      // Met à jour le produit Stripe avec les données autres que les tags
      // Assurez-vous que la fonction updateStripeProduct peut gérer cette mise à jour sans les tags,
      // ou adaptez-la si nécessaire pour inclure également les tags
      await updateStripeProduct(videoId, otherUpdatedData);
  
      // Met à jour le document Firestore pour la vidéo avec toutes les données mises à jour, y compris les tags
      await updateDoc(doc(db, "videos", videoId), updatedData);
  
      // Désactive le mode d'édition pour la vidéo actuelle
      setIsEditing(prev => ({ ...prev, [videoId]: false }));
  
      // Optionnel : Met à jour l'état local des vidéos avec les nouvelles données
      // Cela évite de devoir refaire une requête pour toutes les vidéos après la mise à jour
      setVideos(prevVideos => prevVideos.map(video => video.id === videoId ? { ...video, ...updatedData, tags: tagsArray } : video));
  
      // Affiche un message de succès ou met à jour l'UI selon les besoins
      console.log("Vidéo mise à jour avec succès");
    } catch (error) {
      console.error("Erreur lors de la mise à jour de la vidéo:", error);
      // Gérez les erreurs éventuelles, comme l'affichage d'un message d'erreur à l'utilisateur
    }
  };
  


  const handleDelete = async (videoId) => {
    const isConfirmed = window.confirm("Êtes-vous sûr de vouloir supprimer cette vidéo ?");
    if (isConfirmed) {
      await deleteDoc(doc(db, "videos", videoId));
      // Optionally, refetch videos or update local state
    }
  };

   // Appelée pour commencer à éditer une vidéo
   const handleStartEdit = (videoId) => {
    setIsEditing(prev => ({ ...prev, [videoId]: true }));
  };

  // Appelée pour annuler l'édition
  const handleCancelEdit = (videoId) => {
    setIsEditing(prev => ({ ...prev, [videoId]: false }));
    // Optionnel : Réinitialiser l'état d'édition à l'état initial pour cette vidéo
    setEditState(prev => ({
      ...prev,
      [videoId]: {
        title: videos.find(video => video.id === videoId).title,
        price: videos.find(video => video.id === videoId).price,
        description: videos.find(video => video.id === videoId).description
      }
    }));
  };

  return (
    <Box
    sx={{
      backgroundColor: 'var(--secondary-black)',
      borderRadius: '10px',
      padding: '20px',
      // Appliquez d'autres styles ici pour un UI moderne
    }}
  >
    {videos.map(video => (
      <Card key={video.id} sx={{ marginBottom: 2 }}>
        <CardContent>
          {isEditing[video.id] ? (
            <>
              <TextField
                label="Titre"
                variant="outlined"
                fullWidth
                margin="dense"
                value={editState[video.id].title}
                onChange={(e) => setEditState(prev => ({ ...prev, [video.id]: { ...prev[video.id], title: e.target.value } }))}
              />
              <TextField
                label="Prix"
                type="number"
                variant="outlined"
                fullWidth
                margin="dense"
                value={editState[video.id].price}
                onChange={(e) => setEditState(prev => ({ ...prev, [video.id]: { ...prev[video.id], price: e.target.value } }))}
              />
              <TextField
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                margin="dense"
                value={editState[video.id].description}
                onChange={(e) => setEditState(prev => ({ ...prev, [video.id]: { ...prev[video.id], description: e.target.value } }))}
              />
              <TextField
                label="Tags (séparés par un point-virgule)"
                variant="outlined"
                fullWidth
                margin="dense"
                value={editState[video.id].tags}
                onChange={(e) => setEditState(prev => ({
                  ...prev, 
                  [video.id]: { 
                    ...prev[video.id], 
                    tags: e.target.value 
                  }
                }))}
                placeholder="Tags (séparés par un point-virgule)"
              />

              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button variant="contained" color="primary" onClick={() => handleUpdate(video.id)}>Valider</Button>
                <Button variant="outlined" color="secondary" onClick={() => handleCancelEdit(video.id)}>Annuler</Button>
              </Box>
            </>
          ) : (
            <>
              <Typography variant="h5" color="black">{video.title}</Typography>
              <Typography variant="body1" color="black">Prix: {video.price}€</Typography>
              <Typography variant="body2" color="black">Description: {video.description}</Typography>
              <Box display="flex" justifyContent="space-between" marginTop={2}>
                <Button variant="contained" onClick={() => handleStartEdit(video.id)}>Modifier</Button>
                <Button variant="outlined" color="error" onClick={() => handleDelete(video.id)}>Supprimer</Button>
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    ))}
  </Box>
  );
}

export default VideoManagement;