import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig'; // Vérifie le chemin
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { Box, Card, CardContent, CardMedia, Chip, Grid, Typography } from '@mui/material';

const auth = getAuth();

function Feed() {
  const navigate = useNavigate();
  const [videos, setVideos] = useState([]);
  const currentUser = auth.currentUser;

  useEffect(() => {
    const fetchVideos = async () => {
      const videosCollectionRef = collection(db, "videos");
      const data = await getDocs(videosCollectionRef);
      setVideos(data.docs.map(doc => ({ ...doc.data(), id: doc.id })));
    };

    fetchVideos();
  }, []);

  const handleVideoClick = (video) => {
    if (currentUser && video.accessList && video.accessList.includes(currentUser.uid)) {
      navigate(`/purchased-videos/video/${video.id}`);
    } else {
      navigate(`/video/${video.id}`);
    }
  };
  const getRandomBgColor = () => {
    const hue = Math.floor(Math.random() * 360);
    return `hsl(${hue}, 70%, 80%)`; // Modifiez la saturation et la luminosité selon vos préférences
  };
  return (
    <Box sx={{ flexGrow: 1, p: 1, overflow: 'hidden' }}>
    <Grid container spacing={3}>
      {videos.map((video) => (
        <Grid item xs={6} sm={6} md={4} key={video.id} onClick={() => handleVideoClick(video)}>
          <Card sx={{ 
            height: '100%', 
            display: 'flex', 
            flexDirection: 'column', 
            cursor: 'pointer', 
            borderRadius: '25px',
            backgroundColor: 'var(--secondary-black)'
          }}>
            <CardMedia
              component="img"
              height="240"
              image={video.previewUrls[0]}
              alt="Preview"
            />
            <CardContent>
           
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '0.5rem', color:'black' }}>
                {video.tags && video.tags.map((tag, index) => (
                  <Chip key={index} label={tag} sx={{ color:'black', backgroundColor: getRandomBgColor() }}  />
                ))}
              </div>
              <Typography gutterBottom variant="body1" component="h2">
                {video.duration > 0 && `${video.duration} min`}
              </Typography>
              <Typography variant="body2" color="var(--text-color)">
                {video.storagePaths.length} vidéo{video.storagePaths.length > 1 ? 's' : ''}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </Box>
  );
}

export default Feed;
